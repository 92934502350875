@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-form-upload {
  width: 97%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 30px;
}

.container-form-upload h2 {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 36px;
}

.container-form-upload p {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px;
}

.info-upload {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 11px;
}

.submit-upload {
  font-size: 14px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #337ab7;
  border-color: #2e6da4;
  width: 70px;
  margin-top: 15px;
}

.submit-upload:hover {
  background-color: blue;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-Roman.da8986e1.otf) format("opentype");
}

td {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9vw;
}

.total-record-container {
  margin: 10px 20px;
  font-size: 18px;
  font-weight: bold;
}
.response-success{
  margin: 10px 0;
  color: green;
  text-align: left;
}
.response-error{
  margin: 10px 0;
  color: red;
  text-align: left;
}
.response-warning{
  margin: 10px 0;
  color: #f8bb86;
  text-align: left;
}

.container-donation-list {
  padding: 28px;
}
.container-action{
  display: flex;
  flex-direction: row;
  margin-bottom: 2vh;
}
.data-table-container{
  /* height: 70vh; */
}
.Mui-TableHeadCell-Content-Wrapper{
  white-space: nowrap !important;
  
}
.container-donation-list h1 {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 30px;
}

.info-download {
  color: #337ab7;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.icon-download {
  color: #337ab7;
}

.link-download:hover {
  color: rgb(48, 48, 177);
}

.upload-add-row-container {
  width: 100%;
  border: solid 1px #ccc;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.upload-add-form {
  margin: 0 5px;
  width: 20%;
  min-width: 9vw;
}

.react-bootstrap-table {
  overflow-x: auto;
  font-weight: 200 !important;
}

svg {
  height: 1rem !important;
  width: 1rem !important;
  margin-left: 1px;
  cursor: pointer;
}

.MuiTablePagination-root {
  margin-top: unset !important;
  margin-bottom: 12px !important;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-Roman.da8986e1.otf) format("opentype");
}

.container-info-detail {
  padding: 0 19px 0 19px;
  margin-top: 5px;
}

.btn-cancel {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-left: 3px;
}

.btn-cancel-detail {
  background-color: #fff;
  border-color: #ccc;
  color: #333;
  width: 90px;
  height: 36px;
}

.btn-cancel-detail:hover {
  background-color: gray;
}

.container-content-detail {
  padding: 0 10vw;
}

.icon-cloud {
  margin: auto;
  height: 31px;
  width: 33px;
  margin: 0 auto;
}

.title-detail {
  font-size: 18px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #777777;
  background-color: #f5f5f5;
  padding: 10px;
}

.content-detail {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  margin: 0;
}

.content-detail {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333333;
  margin: 0;
}

